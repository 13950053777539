



























import Vue from "vue";
import { mapGetters } from "vuex";
import FullScreenDialog from "@/components/FullScreenDialog.vue";
import CategoriesForm from "./components/CategoriesForm.vue";
import CategoriesTable from "./components/CategoriesTable.vue";
import CategoriesFilters from "./components/CategoriesFilters.vue";

import { Category } from "@/store/types";

export default Vue.extend({
  name: "CategoriesPage",

  components: {
    FullScreenDialog,
    CategoriesForm,
    CategoriesTable,
    CategoriesFilters
  },

  data: () => ({
    fullScreenDialog: false,
    category: null as unknown | Category,
  }),

  computed: {
    ...mapGetters({
      categories: "categoriesModule/getCategories",
      total: "categoriesModule/getTotal",
      loading: "categoriesModule/getLoading"
    })
  },

  created() {
    this.$store.dispatch("categoriesModule/fetch")
  },

  methods: {
    onUpdate(category: Category): void {
      this.$store.dispatch("categoriesModule/update", category);
    },

    editCol(category: Category): void {
      this.category = category;
      this.openFullScreenDialog();
    },

    deleteCol(category: Category): void {
      this.$store.dispatch("categoriesModule/archive", category._id);
    },

    fetch(payload: { page: number, pageSize: number }): void {
      this.$store.dispatch("categoriesModule/fetch", payload);
    },

    openFullScreenDialog(): void {
      this.fullScreenDialog = true;
    },

    closeFullScreenDialog(): void {
      this.fullScreenDialog = false;
      this.category = null;
    },
  }
})
