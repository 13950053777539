


























































import { Category, ImageUpload } from "@/store/types";
import Vue from "vue";
import { mapGetters } from "vuex";
import {v4 as uuidv4} from "uuid";

export default Vue.extend({
  name: "CategoriesForm",

  props: {
    category: { type: Object, default: null }
  },

  data() {
    return {
      loading: false,
      options: [
        { text: "Sim", value: true },
        { text: "Não", value: false }
      ],
      payload: {
        _id: "",
        name: "",
        logo: "",
        store: "",
        affiliate_platform_id: null,
        active: true,
        filterable: true,
        created_at: new Date(),
        updated_at: new Date(),
      } as Category
    };
  },

  computed: {
    ...mapGetters({
      platforms: "affiliatePlatformsModule/getPlatforms",
    })
  },

  mounted() {
    this.$store.dispatch('affiliatePlatformsModule/fetch', { page: 0, pageSize: -1 })
    if (this.category) {
      Object.assign(this.payload, this.category)
    }
  },

  methods: {
    save(): void {
      if (!this.validateForm()) return;
      this.loading = true;

      this.payload.updated_at = new Date()
      this.$store
          .dispatch("categoriesModule/update", this.payload)
          .then(() => this.$emit("close"))
          .catch((e) => this.$store.dispatch("app/showIsError", e.message))
          .finally(() => this.loading = false);
    },

    validateForm(): boolean {
      if (!this.payload.name) {
        this.showError("Insira o Nome da Categoria!");
        return false;
      }
      if (!this.payload.translated_name) {
        this.showError("Insira a Tradução do Nome da Categoria!");
        return false;
      }
      return true;
    },

    showError(message: string): void {
      this.$store.dispatch("app/showCustomError", message);
    },

    getFile(file: File) {
      if (!file.type.includes("image")) {
        return this.$store.dispatch("app/showCustomError", "Arquivo não Suportado!");
      }

      const extension = file.name.split(".")[1];

      const reader = new FileReader();
      reader.onload = async ({ target }) => {
        const image: ImageUpload = {
          folder: "/categories",
          filename: `${uuidv4()}.${extension}`,
          file: new Uint8Array(target?.result as ArrayBuffer),
          metadata: {
            contentType: file.type,
            size: file.size,
          }
        }

        this.$store.dispatch("uploader/upload", image)
            .then(url => {
              this.payload.logo = url
            })
      };
      reader.readAsArrayBuffer(file);
    }
  }
});
