var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"categories-table"},[_c('ConfirmDialog',{attrs:{"title":"Arquivar Categoria?","text":"Essa ação não pode ser revertida!","show":_vm.showDialog},on:{"close":function($event){_vm.showDialog = false},"confirm":_vm.onArchive}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"options":_vm.options,"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.pageSize,"loading":_vm.loading,"server-items-length":_vm.total},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.logo",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticStyle:{"height":"50px","width":"50px","margin":"5px"},attrs:{"src":item.logo,"aspect-ratio":"1"}})]}},{key:"item.filterable",fn:function(ref){
var item = ref.item;
return [(item.filterable)?_c('v-btn',{staticClass:"m-small",attrs:{"icon":"","color":"green"}},[_vm._v(" Sim ")]):_c('v-btn',{staticClass:"m-small",attrs:{"icon":"","color":"red"}},[_vm._v(" Não ")])]}},{key:"item.info",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{staticStyle:{"width":"40px","margin-left":"auto"},attrs:{"inset":""},on:{"click":function($event){return _vm.onUpdate(Object.assign({}, {active: !item.active}, item))}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"blue","disabled":item.archived},on:{"click":function($event){return _vm.onEdit(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":"","color":"red","disabled":item.archived},on:{"click":function($event){return _vm.handleShowDialog(item)}}},[_c('v-icon',[_vm._v("mdi-archive")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }