<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-container style="margin: 10px 0 0; padding: 0">
      <v-row>
        <v-col cols="12" md="2">
          <v-text-field
            v-model="name"
            label="Título"
            required
          />
        </v-col>

        <v-col cols="12" md="2">
          <v-text-field
            v-model="store"
            label="Loja (Key)"
            required
          />
        </v-col>

        <v-col cols="12" md="2">
          <v-select
            v-model="affiliate_platform_id"
            :items="platforms"
            item-text="name"
            item-value="_id"
            label="Plataforma"
          />
        </v-col>

        <v-col cols="12" md="1">
          <v-select
            v-model="filterable"
            :items="options2"
            item-text="text"
            item-value="value"
            label="Filtrável"
          />
        </v-col>

        <v-col cols="12" md="1">
          <v-select
            v-model="active"
            :items="options"
            item-text="text"
            item-value="value"
            label="Status"
          />
        </v-col>

        <v-col cols="12" md="3" style="margin-top: 20px">
          <v-btn
            small
            color="error"
            class="mr-4"
            @click="reset"
          >
            Limpar
          </v-btn>

          <v-btn
            small
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="handleFilter"
          >
            Buscar
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script lang="js">

import {mapGetters} from "vuex";

export default {
  name: "CategoriesFilters",

  data: () => ({
    valid: true,
    name: null,
    store: null,
    active: null,
    filterable: null,
    affiliate_platform_id: null,
    options: [
      { text: "Ativo", value: true },
      { text: "Inativo", value: false }
    ],
    options2: [
      { text: "Sim", value: true },
      { text: "Não", value: false }
    ],
    checkbox: false,
  }),

  computed: {
    ...mapGetters({
      platforms: "affiliatePlatformsModule/getPlatforms",
    })
  },

  mounted() {
    this.$store.dispatch('affiliatePlatformsModule/fetch', { page: 0, pageSize: -1 })
  },

  methods: {
    handleFilter () {
      const { name, store, active, filterable, affiliate_platform_id } = this
      this.$store.commit("categoriesModule/setFilters", { name, store, active, filterable, affiliate_platform_id })
      this.$store.dispatch("categoriesModule/fetch")
    },

    reset () {
      this.$refs.form.reset()
      this.$store.commit("categoriesModule/setFilters", {})
      this.$store.dispatch("categoriesModule/fetch")
    }
  },
}
</script>

<style scoped>

</style>
