





















export default {
  name: "ConfirmDialog",
  props: {
    title: { type: String, required: true },
    text: { type: String, required: true },
    show: { type: Boolean, defaul: false }
  }
};
