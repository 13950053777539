






















































import Vue from "vue";
import { Category } from "@/store/types";
import ConfirmDialog from "../../../components/ConfirmDialog.vue";

export default Vue.extend({
  name: "CategoriesTable",

  components: {
    ConfirmDialog,
  },

  props: {
    items: { type: Array, default: null },
    loading: { type: Boolean, default: false },
    total: { type: Number, default: 0 }
  },

  data: () => ({
    showDialog: false,
    selectedItem: null as unknown | Category,
    options: {},
    page: 1,
    pageSize: 10,
    headers: [
      {
        text: "Imagem",
        value: "logo",
        sortable: false,
        width: "100"
      },
      {
        text: "Nome",
        align: "start",
        value: "name",
      },
      {
        text: "Nome (Traduzido)",
        align: "start",
        value: "translated_name",
      },
      {
        text: "Plataforma",
        align: "center",
        value: "affiliate_platform_id.name",
      },
      {
        text: "Loja",
        align: "center",
        value: "store",
      },
      {
        text: "Usar em Filtros?",
        align: "center",
        value: "filterable",
        sortable: false
      },
      {
        text: "Status",
        value: "info",
        align: "end",
        sortable: false
      },
      {
        text: "Ações",
        value: "actions",
        width: "110",
        align: "center",
        sortable: false
      }
    ] as Array<Object>
  }),

  watch: {
    options: {
      handler (options) {
        this.fetch(options.page, options.itemsPerPage);
      },
      deep: true,
    },
  },

  methods: {
    fetch(page: number, pageSize: number): void {
      if (page === this.page && pageSize === this.pageSize) return;
      this.page = page;
      this.pageSize = pageSize;

      this.$emit("fetch", { page: page - 1, pageSize })
    },

    handleShowDialog(item: Category): void {
      this.showDialog = true
      this.selectedItem = item;
    },

    onUpdate(item: Category): void {
      this.$emit("onUpdate", item)
    },

    onEdit(item: Category): void {
      this.$emit("onEdit", item)
    },

    onArchive(): void {
      this.$emit("onDelete", this.selectedItem)
      this.showDialog = false
    },
  }
})
